.events {
  padding: 1.5em 2em;

  border-bottom: 5px solid $gold;
  background-color: $gold-20;
}

.subHeader .events {
  margin-top: 50px;
  margin-bottom: 5em;

  border-top: 5px solid $gold;
}

.events-contact {
  height: 100%;
  right: 0;

  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  border-left: 3em solid #ffffff;
  background-color: $gold-20;

  justify-content: space-around;
}

ul.eventlist {
  li {
    font-weight: 600;

    transition: background-color $transition-time-fast ease;

    & + li {
      margin-top: 1em;
    }

    a {
      display: flex;

      text-decoration: none;
      text-transform: none;

      span.inner-text {
        display: flex;
        flex-direction: column;

        justify-content: center;
      }
    }

    &:hover {
      background-color: #ebe3d1;
    }

    p {
      font-weight: 300;

      margin: 0;

      color: $grey;
    }

    span.date {
      font-size: 1.2em;
      font-weight: 600;
      line-height: 1em;

      display: flex;

      width: 100px;
      min-width: 100px;
      height: 100px;
      margin-right: 2em;

      text-align: center;

      color: white;
      border: 4px solid white;
      background-color: $turquoise;

      align-items: center;
      justify-content: center;
    }

    span.mod-articles-category-title {
      text-decoration: none;

      color: $grey;
    }
    a:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.event-details {
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;

  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: stretch;

  .introimg {
    margin-bottom: 0;
  }
}

.subDatePos {
  margin: 0.5em 2rem;
  dd, p {
    -webkit-margin-start: 4px;
    margin: 0 0 0 4px;
  }
}

.subDate {
  font-size: 1.8em;
  font-weight: 600;
  line-height: 1em;

  float: left;

  width: 3.53535em;
  height: 3.53535em;
  padding: 27px 0 0 0;

  text-align: center;

  color: white;
  border: 4px solid white;
  background-color: $turquoise;
}

.mod-articles-category-group:last-of-type .date:last-of-type {
  margin-bottom: 10px;
}

.row h3 {
  text-align: center;
}

.eventsTitle {
  font-size: 1.3em;
  font-weight: 600;

  display: block;

  margin-bottom: 0.5em;

  text-transform: uppercase;

  color: $turquoise;
}

.customall {
  margin: 1em auto 3em;
  .allevents {
    text-align: center;
    a {

      button {
        border-radius: 0;
        font-size: 0.8em;
      }
    }
  }
}

.events-content {
  margin: 1em 0 0 5%;
  h1 {
    margin-bottom: 0;
  }
  h3 {
    margin-top: 0.3em;
    margin-bottom: 0;

    font-weight: 500;
  }
}

@media (max-width: 1522px) {
  .events-contact {
    font-size: 0.8em;
  }
}

@media (min-width: 992px) and (max-width: 1258px) {
  .subDate {
    display: none;
  }
  dd {
    margin: 0;
  }
  .events-contact {
    border-left: none;
  }
}

@media only screen
and (min-device-width : 768px)                    // iPad Retina Support
and (max-device-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 2) {
  .event-details .events-contact {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    ms-flex-order: 3;
    order: 3;

    border-left: none;

    width: 100%;
    min-height: 231px;
    max-height: 231px;
  }
  .subDate {
    display: none;
  }
  dd {
    margin: 0;
  }
  dl.fields-container {
    margin: 1em 0;
  }
  .subIntroimg, .subIntroimg img{
    width: auto;
  }
}

@media(max-width: $screen-sm-max) {
  .event-details .events-contact {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    ms-flex-order: 3;
    order: 3;

    border-left: none;

    width: 100%;
    min-height: 231px;
    max-height: 231px;
  }
  .subDate {
    display: none;
  }
  dd {
    margin: 0;
  }
  dl.fields-container {
    margin: 1em 0;
  }
}

@media(max-width: $screen-xs-max) {
  .events-contact {
    font-size: 1em;
  }
}

//replace with variable
@media(max-width: 480px) {
  .events {
    padding: 1.5em 1em;
  }

  ul.eventlist li {
    a {
      flex-direction: column;

      span {
        &.date {
          width: 100%;
          height: auto;
          margin-bottom: 0.2em;
          padding: 0.5em 0;
        }
        &.inner-text {
          text-align: center;
        }
      }
    }
  }
}