.zg_button,
form div input.btn.subbutton,
.intro-slider .watermark a,
.wgforms button {
  color: #ffffff;
  background-color: $turquoise;
  text-align: center;
}

.zg_button:hover,
.zg_button:active,
form div input.btn.subbutton:hover,
.intro-slider .watermark a:hover,
.wgforms button:hover {
  color: $grey;
  background-color: $turquoise-50;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

form div input.btn.subbutton {
  max-width: 220px;
  border: none;
  width: 100%;
  padding: 0.25em 0.75em;
  font-size: 1rem;
  border-radius: 0;
}