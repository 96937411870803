.back-to-top {
  visibility: hidden;

  transition: opacity $transition-time-fast ease;

  opacity: 0;
  color: white;
  &.scrolled {
    visibility: visible;

    opacity: 1;
    color: white;
  }
  i.fa.fa-angle-up {
    margin: 5px 0 0 0;

    &:before {
      color: white;
    }
  }

  a {
    font-size: 1.2em;
    line-height: 1.4em;

    position: fixed;
    z-index: 9999;
    right: 15px;
    bottom: 9px;

    width: 1.5em;
    height: 1.5em;

    transition: background-color $transition-time-fast ease;
    text-align: center;

    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}
