.cc-window {
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .3);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, .3);
}

.cc-compliance {
  -webkit-box-flex: inherit !important;
  -ms-flex: inherit !important;
  flex: inherit !important;
  ms-flex: inherit !important;
}

.cc-remove {
  position: absolute;
  padding: .5em;
  right: 0;
  cursor: pointer;
}
