.page-header {
  display: none;
}

#header {
  z-index: 5002;
  .logo-full {
    width: 100%;
    max-width: 286px;
    height: auto;
  }

  &.fixed {
    position: fixed;
  }

  &.topmenu {
    position: fixed;
    z-index: 5002;

    display: flex;

    width: 100%;
    max-width: 1920px;

    transition: background-color 0.3s ease;

    border-bottom: 2px solid $turquoise;
    background-color: rgba(255, 255, 255, 1);

    align-items: baseline;

    img {
      max-height: 5em;

      transition: max-height 0.3s ease;
    }
  }

  .mainnav {
    //margin-top: 48px;
    transition: margin-top 0.3s ease;
    ul.nav.menu {
      /** just for better understanding, there are two menues, the second one is just hidden in non-mobile viewports */
      display: none;
      &:first-child {
        display: block;
      }

      li {
        &.active,
        &.current {
          background-color: rgba(93, 104, 109, 0.3);

          a {
            color: white;
          }
        }
        a {
          font-size: 0.79em;
          font-weight: 600;

          cursor: pointer;
          text-transform: uppercase;

          color: $grey;
        }
      }
    }
  }
}

.logo-mobile,
.nav-trigger {
  display: none;
}

@media(min-width: 1270px) and (max-width: 1500px) {
  .mm {
    font-size: 0.9em;
  }

  div.mainnav li a {
    padding: 7px;
  }
}

@media(min-width: $screen-md-min) {
  div.mainnav {
    line-height: 1;

    margin-top: 1.5em;

    align-self: center;
  }
}

@media(max-width: 1060px) {
  #header {
    &.topmenu {
      min-height: 3em;

      align-items: center;
      justify-content: space-around;

      .nav-trigger {
        display: flex;

        padding: 0 15px;
        //align-items: center;
      }
    }

    /** this is where we toggle the visibility of the mobileonly part of the main menue*/
    .mainnav ul.nav.menu {
      display: block;
    }


    .mainnav {
      top: 40px;
    }

    &.scrolled {
      .mainnav {
        top: 33px;
      }
    }
  }

  .mainnav {
    position: absolute;

    ul.nav {
      margin-top: 0;

      transition: top 0.3s;
    }
  }


  .logo-mobile,
  .nav-trigger {
    display: block;
  }


  #header.topmenu {
    #logo * {
      transition: opacity 1s;
    }

    &.scrolled .logo-mobile,
    .logo-full {
      max-height: 100%;

      opacity: 1;
    }

    &.scrolled .logo-full,
    .logo-mobile {
      display: block;

      max-height: 0;

      opacity: 0;
    }
  }

  .scrolled .mm {
    top: 3em;
  }
  .mm {
    position: fixed;
    top: 4.95em;
    left: 0;

    display: none;

    width: 70%;
    height: 100%;

    background-color: white;
    box-shadow: 7px 0 7px rgba(0, 0, 0, 0.3);

    ul {
      display: block;
    }

    li {
      display: block;

      a {
        font-size: 0.8em;
        font-weight: 400;
      }
    }

    li > li {
      line-height: 1.2;

      width: 100%;

      border-bottom: 1px solid #85c3cd;

      &.active {
        background-color: $grey;

        a {
          color: white;
        }
      }
    }
  }
}

@media (min-width: 1060px) and (max-width: 1270px) {
  #header .mainnav ul.nav.menu li a {
    font-size: 0.6em;
    padding: 10px 5px;
  }
}
