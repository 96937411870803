@media print {
  * {
    color: black !important;
    background-color: transparent !important;
    font-family: "Hind", sans-serif !important;
    overflow: visible !important;
  }

  #wrapper {
    display: block !important;
    min-height: 0 !important;
  }

  //@page:first header { display:block; }

  body {
    height: auto !important;
    overflow-y: visible !important;
    display: block !important;
    //float: none !important;
    //position: relative !important;
    //flex: none !important;
  }

  .container {
    width: 100% !important;
  }

  div[itemprop="articleBody"] p:first-of-type {
    margin-top: 0 !important;
  }

  ul.eventlist li {
    margin-top: 8px !important;
    height: 70px !important;
  }

  h1 {
    margin-top: 0 !important;
    font-size: 24px !important;
  }

  h2, h3, h4 {
    font-size: 20px !important;
  }

  p, span.date {
    font-size: 15px !important;
  }

  body {
    line-height: 1.2 !important;
  }

  .row > .col-md-12 > div.col-md-7.col-xs-12 {
    width: 100% !important;
  }

  header, .welcome, #newsletter, .phone, footer, .events, .logo-mobile {
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    position: relative !important;
  }

  .logo-mobile, .logo-mobile img, .logo-full {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;

    margin: 0 auto !important;

    text-align: center !important;
  }

  main {
    margin-top: 0 !important;
  }

  #logo {
    width: 100% !important;
  }

  .phone {
    position: relative !important;
    left: 0 !important;
    display: block !important;
    p {
      letter-spacing: 0 !important;
    }
  }

  .subDatePos {
    text-align: center !important;
  }

  .acymailing_module,
  .intro-slider,
  .menu_side,
  #newsletter p:last-of-type,
  .nl,
  #gallery-container,
  .swipebox-gallery img,
  .nav-trigger,
  .mainnav,
  .legal,
  .introimg img,
  .subIntroimg,
  .eventsTitle,
  .back-to-top,
  .allevents,
  .customall,
  .contact-form {
    display: none !important;
  }
}