//the main scss file where every other scss will get imported

/** vendor imports, like bootstrap, fontawesome etc. */
@import "vendor/bootstrap";
@import "vendor/swipebox";


/** general settings, like reset, variables and mixins */
@import "general/reset";
@import "general/variables";
@import "general/colors";
@import "general/general";
@import "general/form";
@import "general/coockieconsent";

/** layouting e.g. header, main, footer */
@import "layout/header";
@import "layout/swipebox";
@import "layout/back-to-top";
@import "layout/footer";
@import "layout/buttons";
@import "layout/print";
@import "layout/ie";
@import "layout/cookie-consent";


/** custom page definitions */
@import "pages/home";
@import "pages/home/slider";
@import "pages/home/newsletter";
@import "pages/home/menu-side";
@import "pages/home/events";
@import "pages/subpage";





