html {
  overflow-x: hidden;
  //sticky footer hack, see https://css-tricks.com/couple-takes-sticky-footer/#article-header-id-3
  overflow-y: scroll;

  height: 100%;
}

body {
  height: 100%;
}

#wrapper {
  display: flex;
  flex-direction: column;

  height: auto !important;
  min-height: 100%;
  //height: 100%;
  overflow-x: hidden;
}

main {
  flex: 1;

  margin-top: 5em;
  margin-bottom: 3em;
}

html,
body {
  font-family: "Hind", sans-serif;
  font-size: 22px;
  line-height: 1.5;

  position: relative;

  color: $grey;
}

a {
  color: $gold;
}

a {
  &:hover,
  &:focus {
    color: $gold;
    text-decoration: underline;
  }
}

img,
video {
  max-width: 100%;
  height: auto;
}

h1,
p.h1 {
  font-size: 1.75em;
  line-height: 1.2;

  text-transform: uppercase;
}

h2,
h3,
h4 {
  font-size: 1.5em;
  line-height: 1.2;

  text-transform: uppercase;
}

h5 {
  font-size: 1.4em;
  line-height: 1.2;
}

h6 {
  font-size: 1.1em;
  font-weight: 800;
  line-height: 1.2;

  margin-top: 20px;
  margin-bottom: 5px;

  text-align: left;
  letter-spacing: 0;
}

h1,
h2 {
  text-align: center;
}

hr {
  margin: 1.5em 0;

  border-color: $grey;
}

.content {
  position: relative;
  z-index: 10;
}

.bigger {
  font-size: 1.125em;
}

.noScroll {
  overflow: hidden;
}

.margin-top {
  margin-top: 2em;
}

.image-wrapper {
  margin-top: 2em;
  margin-bottom: 2em;
}

.flex-wrap {
  display: flex;

  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
}

ul.dot-circle,
.items-leading ul {
  li {
    position: relative;

    margin-left: calc(1em + 0.75em);

    &:before {
      font-family: FontAwesome, sans-serif;

      position: absolute;
      left: -1.75em;

      width: 1em;

      content: "\f192";
    }
  }
}

.left {
  text-align: left;
}

#wrapper > div:nth-child(2) > div.item-page > div > div > div > div > div > p:nth-child(2) {
  margin-top: 0;
}

.semi-bold {
  font-weight: 400;
}

.bold {
  font-weight: 600;
}

li > a {
  font-weight: 600;

  display: inline-block;

  cursor: default;
  text-transform: uppercase;

  color: $grey;
}

h3.bottom {
  font-weight: 400;

  margin: 1.51515em 0 0.5em 0;

  text-transform: none;
}

div[itemprop="articleBody"] {
  p {
    margin-bottom: 11px;
  }
  p:first-of-type {
    margin-top: 50px;
  }
}

.col-md-35 {
  position: relative;

  float: left;

  width: 35%;
}

.col-md-30 {
  position: relative;

  float: left;

  width: 30%;
}

div.contact ul li {
  text-align: center;
}

.subTop {
  position: relative;

  overflow: hidden;
  //height: 100%;
}

div[itemprop="articleBody"].col-md-8 h1 {
  text-transform: none;
}

#logo {
  text-align: center;
}

.custom .row .col-md-12 .col-md-12 h3 {
  margin-top: 50px;
}

.custom .row .col-md-12 .col-md-6 p {
  margin-bottom: 1em;
}

div[itemprop="articleBody"] h1,
div[itemprop="articleBody"] h3 {
  clear: both;

  text-align: left;
}

div[itemprop="articleBody"] h3 {
  text-transform: none;
}

i.fa.fa-bars {
  padding: 10px;
}

#mmoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: none;

  background-color: black;
  opacity: 0.2;

  z-index: 5000;
}

#error {
  text-align: center;
  #sorry {
    text-transform: uppercase;
  }
  p {
    text-align: center;
    &:first-of-type {
      margin-top: 20px;
    }
  }
  #backtohome {
    text-align: center;
    button {
      border-radius: 0;
      font-size: 0.9em;
    }
  }
  img {
    margin: 30px auto 0;
  }
  .sep {
    background-image: url("/images/separator.png");
    background-image: url("/images/separator.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    border: none;
    height: 17px;
  }
}

@media(max-width: $screen-xs-max) {
  html,
  body {
    font-size: 17px;
  }
  main {
    margin-top: 4.5em;
  }
  p.text-center {
    text-align: left;
  }
  .nav-trigger {
    display: block;
  }
  .expand {
    display: block;
  }
  .col-md-30 {
    width: inherit;
  }

}

@media (max-width: $screen-md-min) {
  .col-md-4 h3 {
    text-align: center;
    .flex-wrap {
      justify-content: center;
    }
  }
}

/** href ext */

a[href^="http"]:not(.ignore-ext)::after,
a[href^="http"] button:not(.ignore-ext)::after{
  content: "\f08e";
  font-family: FontAwesome;
  margin-left: 5px;
  font-size: 0.9em;
  vertical-align: middle;
}

input#evs_checkbox_accept {
  width: inherit;
  height: inherit;
  box-shadow: none;
}

.has-error.has-feedback label~.form-control-feedback {
  top: 38px;
  right: 20px;
  display: block;
  font-size: 0.8em
}

.has-error.has-feedback label.checkbox~.form-control-feedback {
    display: none;
}

.form-control-feedback {
  display: none;
}

.note-required input {
  display: none;
}

label.checkbox {
  font-size: 0.6em;
  margin: 0 0 0 2em;
}

#note_required-lbl {
  margin-left: 1.5em;
  font-size: 0.6em;
}

@media(max-width: $screen-sm-max) {
  .has-error.has-feedback label~.form-control-feedback {
    top: 38px;
    right: 73px;
  }
}

@media(max-width: $screen-xs-max) {
  .has-error.has-feedback label~.form-control-feedback {
    top: 30px;
    right: 70px;
  }
  #note_required-lbl {
    margin-left: 0;
  }
}