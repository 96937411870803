.menu_side {
  display: flex;

  margin-top: 0.6em;

  flex-flow: column wrap;

  li {
    margin-left: 80px;

    & + li {
      margin-top: 9px;
    }

    &:before {
      position: absolute;
      top: 0;
      left: -80px;

      display: inline-block;

      width: 60px;
      height: 60px;

      content: "";
      transition: background-image $transition-time-fast ease;

      background-image: url("/images/herz-2.png");
      background-size: 60px;
    }

    &:hover {
      a {
        background-color: #eeeeee;
      }

      &:before {
        background-image: url("/images/herz-1.png");
      }
    }

    &.item-101 {
      display: none;
    }

    a {
      font-size: 0.95em;

      padding: 16px 0;

      cursor: pointer;
      transition: background-color $transition-time-fast ease;

      color: inherit;

      &:after {
        position: absolute;
        bottom: 0;
        left: -3.8em;

        width: 103%;
        height: 100%;

        content: "";

        border-radius: 28px 0 0 38px;
      }
    }
  }
}
//@media (min-width: $screen-sm-max) and (max-width: $screen-md-max) {
//  .menu_side {
//    li {
//      a:after {
//        left: -5.1em;
//      }
//    }
//  }
//}




@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
  .menu_side li a:after {
    left: -5.1em;
    font-size: 0.83em;
    padding: 22px 10px;
    border-radius: 28px 0 0 28px;
  }
}

@media (min-width: $screen-xs-max) and (max-width: $screen-sm-max) {
  .menu_side {
    li {
      a:after {
        left: -3.8em;
      }
    }
  }
}


@media (max-width: $screen-md-max) {
  ul.nav.menu_side {
    margin-bottom: 1em;
  }
}

@media(min-width: $screen-xs-max) and (max-width: $screen-md-max) {

  .menu_side {
    li {
      &:before {
        width: 55px;
        height: 55px;
        background-size: 55px;
      }
      a {
        font-size: 0.87em;
        padding: 13px 0;
        &:after {
          //left: -3.85em
        }
      }
      & + li {
        margin-top: 17px;
      }
    }
  }
}

@media(min-width: 501px) and (max-width: $screen-sm-max) {
  .menu_side {

    li:before {
      width: 60px;
      height: 60px;
      background-size: 60px;
    }
    li + li {
      margin-top: 17px;
    }
    li a {
      font-size: 1.12em;
    }
  }
}

@media(min-width: 501px) and (max-width: $screen-xs-max) {
  .menu_side {
    li {
      a:after {
        left: -4.2em;
      }
    }
  }
}


@media(max-width: $screen-sm-max) {
  .menu_side {
    margin-top: 0;
  }
}

@media(max-width: 500px) {
  .menu_side {
    li {
      margin-left: 60px;

      a {
        font-size: 0.93em;

        padding: 16px 0;
        &:after {
          left: -3.8em;
          padding: 22px 10px;
          border-radius: 22px 0 0 32px;
        }
      }
      & + li {
        margin-top: 8px
      }
      &:before {
        left: -60px;

        display: inline-block;

        width: 50px;
        height: 50px;

        content: "";
        transition: background-image $transition-time-fast ease;

        background-image: url("/images/herz-2.png");
        background-size: 50px;
      }
    }
  }
}