form {
  input {
    &[type="input"],
    &[type="submit"],
    &[type="button"] {
      transition: background-color $transition-time-fast ease, color $transition-time-fast ease;
    }
  }

  textarea {
    resize: vertical;
  }
  .form-group {
    //overflow: hidden;
  }
}

.formname {
  font-size: 1.5em;

  display: block;

  width: 100%;
  margin-top: 1em;
  margin-bottom: 20px;
  padding: 0;

  text-align: center;
}
