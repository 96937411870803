@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #wrapper {//.fixedHeader {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;

    header, footer {
      flex: none;
    }

    .custom .welcome {
      display: block;
    }

    body {
      display: flex;
      margin: 0;
    }
    main {
      flex: 1 1 auto;
    }
    #gallery-container {
      max-height: 17em;
    }
    .welcome p {
      text-align: center;
    }
    .resetie {
      clear: both;
    }
  }
  .subHeader {
    .subIntroimg img {
      height:auto !important;
    }
  }
}

@media screen and (-ms-high-contrast: active) and (max-width: $screen-sm-max), (-ms-high-contrast: none) and (max-width: $screen-sm-max) {
  .subHeader {
    .subIntroimg {
      width: 100%;
      img {
      height:auto !important;
      }
    }
  }
}
@media screen and (-ms-high-contrast: active) and (max-width: $screen-xs-max), (-ms-high-contrast: none) and (max-width: $screen-xs-max) {
  .fixedHeader {
    #gallery-container {
      display: none;

      footer {
        margin-top: 24em;
      }
    }
  }
}
  @media screen and (-ms-high-contrast: active) and (max-width: 360px), (-ms-high-contrast: none) and (max-width: 360px) {
    .fixedHeader {
      #gallery-container {
        max-height: 23em;
      }

    }
  }