.gallery-container {
  margin:  2em 0;
}

.swipebox-gallery {
  margin:  0 auto;

  overflow: hidden;

  width: 100%;

}

.swipebox img {
  width: 16.46%;
  height: auto;
  max-width: 100%;

  margin: 0.1%;

  justify-content: center;
  float: left;
  &:hover {
    opacity: 0.7;
    //transition: opacity 0.3s ease-out;
  }
}


@media (min-width: $screen-xs-max) and (max-width: 1520px) {
  div.swipebox-gallery a:nth-child(1), div.swipebox-gallery a:nth-child(2) {
    display: none;
  }
  .swipebox img {
    width: 19.8%;
  }
}

@media(max-width: $screen-sm-max) {
  .swipebox-gallery {
    //display: none;
  }
  div.swipebox-gallery a:nth-child(1),
  div.swipebox-gallery a:nth-child(2),
  div.swipebox-gallery a:nth-child(3),
  div.swipebox-gallery a:nth-child(4),
  div.swipebox-gallery a:nth-child(5),
  div.swipebox-gallery a:nth-child(9) {
    display: none;
  }
  .swipebox img {
    width: 33.1%;
  }
  #swipebox-close {
    top: 40px;
  }
}

@media(max-width: $screen-xs-max) {
  .swipebox-gallery {

   a:nth-child(1),
   a:nth-child(2),
   a:nth-child(3),
   a:nth-child(4),
   a:nth-child(5),
   a:nth-child(6),
   a:nth-child(7),
   a:nth-child(9) {

    display: none;

  }
  }
  .swipebox img {
    width: 49.7%;
  }
}