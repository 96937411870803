.right ul {
  display: flex;

  justify-content: flex-end;
}


.welcome {
  display: flex;

  max-width: 1920px;
  margin-bottom: 20px;
  padding: 0.5em 0 0.5em 0;

  border-top: 5px solid $gold;
  background-color: $grey;

  align-items: center;
  justify-content: center;
  p {
    font-size: 1.5em;

    margin: 0 auto;
    padding: 10px 0;

    text-align: center;

    color: white;
  }
}

.phone {
  margin: 1em 0 0 0;
  padding: 1em 0 1em 0;

  color: white;
  background-color: $gold;
  p {
    font-size: 1.5em;

    margin: 0 auto;
    padding: 10px 20px;

    text-align: center;
    letter-spacing: 2px;
  }
}
.acyfield_email input {
  color: $grey !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  padding-left: 5px !important;
}


@media(max-width: $screen-xs-max) {
  .phone p {
    font-size: 1.4em;

    padding: 0;

    letter-spacing: 0;
  }

  .welcome p {
    font-size: 1.3em;
  }
}
