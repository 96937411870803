footer {
  border-top: 5px solid $gold;
  background-color: $turquoise;

  .legal,
  .copyright {
    a {
      font-size: 0.75em;
      font-weight: 400;

      display: inline-block;

      padding: 15px 15px;

      cursor: pointer;
      text-decoration: none;

      color: white;

      &:hover {
        background-color: rgba(93, 104, 109, 0.3);
      }
    }
  }

  .legal .nav {
    text-align: right;
  }
}

footer li.active, footer li.current {
  background-color: rgba(93, 104, 109, 0.3);
}

footer .copyright a.excluded:hover {
  background-color: $turquoise;
}



@media(max-width: $screen-md-max) {
  footer {
    .copyright {
      text-align: right;
    }
    .legal .nav {
      text-align: left;
    }
  }

  .push {
    text-align: center;
  }
}

@media(max-width: $screen-xs-max) {
  footer {
    .copyright,
    .legal .nav {
      text-align: center;
    }
  }

  .push {
    text-align: center;
  }
}
