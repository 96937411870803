#newsletter {
  margin-top: 1em;

  p.nl {
    font-size: 1em;
    font-weight: 600;
    margin: 0;
  }

  p.nl:nth-of-type(2) {
    font-size: 0.8em;
    font-weight: 300;
  }

  > p {
    font-size: 1em;

    margin: 0.5em 0 1em 0;
  }

  input {
    width: 100%;
    margin-top: 0;
    padding: 0.25em 0.75em;

    &[id="email"] {
      max-width: 520px;

      border: 1px solid $grey;
      &:focus {
        outline: 0;
      }
    }

  }
}

.acymailing_finaltext {
  font-size: 0.7em;
}

p.acysubbuttons {
  text-align: left;
}

.acymailing_fulldiv div.acymailing_module_success {
  color: inherit;
}

a.close, h4.alert-heading, div.acymailing_footer {
  display: none;
}

div.alert-message {
  text-align: center;
  margin-top: 2em;
}

div.backtohome {
  text-align: center;
  width: 200px;
  margin: 0 auto;
  background-color: #85c3cd;
  color: white;
  margin-top: 1em;

}

div.backtohome:hover {
  color: #5d686d;
  background-color: rgba(133, 195, 205, 0.5);
  transition: background-color 0.3s ease, color 0.3s ease;
}

a.homebutton {
  text-decoration: none;
}