.headline-box {
  line-height: 1.1;

  position: absolute;

  padding: 25px 50px;

  text-align: center;

  color: white;
  border: 2px solid white;
  background-color: $turquoise;
  h1 {
    font-size: 2.5em;
    line-height: 1.1;

    margin: 0;

    text-align: center !important;
    text-transform: none;
  }
  span {
    font-size: 1.4em;
  }
}

.introimg {
  position: relative;
}

div.yoga {
  top: 16%;
  left: 12%;
}

div.steinheilkunde {
  top: 25%;
  left: 5%;
}

div.edelsteingalerie {
  bottom: 11%;
  left: 8%;
}

div.karamkriya {
  bottom: 64%;
  left: 48%;
}

div.seminare {
  top: 65%;
  right: 5%;
}

.lined {
  margin: 1em 0;
  padding: 0.5em 0;

  border-top: $grey 1px solid;
  border-bottom: $grey 1px solid;

  &:nth-of-type(2) {
    margin-bottom: 5em;
  }
}

div > .impressum,
div > .kontakt,
div > .datenschutz {
  font-size: 1em;

  margin-top: 4em;
}

div[itemprop="articleBody"] .impressum p:first-of-type,
div[itemprop="articleBody"] .impressum p:last-of-type {
  margin: 0;
}

form.wgforms {
  margin-top: 0;
}

#button1id {
  font-size: 0.82em;
  &:focus {
    background-color: #85c3cd;
  }
}

#button2id {
  background-color: $gold;

  &:hover {
    color: $grey;
    background-color: $grey-20;
  }
}

.contact-content {
  display: flex;

  justify-content: center;
margin-top: 2.5em;
  ul li:first-of-type {
    font-weight: 600;
  }

  a {
    color: $gold;
  }
}

//.form-control {
//  width: 80%;
//}


.yogaG {
  margin-top: -1em;
  margin-bottom: 2em;
}

.yoga-gallery {
  clear: both;
  overflow: hidden;

  margin-bottom: 2em;

  img {
    float: left;
  }

  img + img {
    margin-left: 4px;
  }

  img:nth-child(n + 5) {
    margin-top: 4px;
  }
}

.kontakt {
  border-top: 2px solid $gold;
  border-bottom: 2px solid $gold;
  background-color: $gold-20;
  h1 {
    padding: 0.5em 0 1em 0;
  }
  .btn {
    border-color: white;
    border-radius: 0;
  }
  li:last-of-type {
    margin-bottom: 5em;
  }
  li a {
    font-weight: 400;
  }
}

div[itemprop="articleBody"] div.impressum {
  margin-bottom: 4em;
}

.col-md-8.text-right {
  padding-right: 0;
}

.datenschutz ul li {
  margin-left: 22px;

  list-style: disc;
}

.datenschutz h2 {
  margin-top: 4em;
}

.kontakt,
.datenschutz,
.impressum {
  margin-top: 6em;
}

p.green {
  color: $grey;
  margin: 3em 0 0 2em;
  text-align: center;
  background-color: #dfc;
}

p.red {
  color: white;
  margin: 3em 0 0 2em;
  text-align: center;
  background-color: #FF0000;
}

.impressum {
  h1 {
    margin-bottom: -1em;
  }
}

.impressum h3 {
  margin: 1.5em 0 0;
}

.datenschutz h3 {
  margin: 1.5em 0 0;
}

@media (max-width: 1660px) {
  div.karamkriya h1,
  div.seminare h1, div.yoga h1 {
    font-size: 1.65em;
  }
  div.yoga {
    padding: 10px 20px;
  }
}

@media (min-width: $screen-md-min) {
  .wgforms label.control-label {
    text-align: right;
  }
}

@media (max-width: $screen-md-max) {
  .yoga-gallery img:nth-of-type(4) {
    margin-top: 4px;
  }
  div.yoga {
    top: 16%;
    left: 10%;
  }
  div.headline-box {
    padding: 10px 20px;
    h1 {
      font-size: 1.5em;
    }
    span {
      font-size: 1.2em;
    }
  }
}

@media (max-width: $screen-sm-max) {
  p.green {
    margin: 0;
  }
  form.wgforms {
    width: 80%;

    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;

    text-align: center;
  }

  .wgforms label.control-label {
    text-align: center;
  }

  .push {
    text-align: center;
  }

  .kontakt,
  .datenschutz,
  .impressum {
    margin-top: 0;
  }
  div[itemprop="articleBody"] div.impressum {
    margin-bottom: 0;
  }
  .subIntroimg .introimg,
  .introimg {
    margin: 0;
    padding: 0;
  }

  .headline-box h1 {
    font-size: 2em;
  }

  .impressum h1 {
    margin-top: 1em;
  }

  .headline-box {
    position: relative;
    top: 0 !important;
    left: 0 !important;

    padding: 15px 0;

    color: $grey;
    background: white;
  }

  div[itemprop="articleBody"] p:first-of-type {
    margin-top: 20px;
  }

  .events-contact p:first-of-type {
    margin-top: 20px;
  }

  .steinheilkunde > h1,
  .edelsteingalerie > h1 {
    font-size: 2em;
  }

  .steinheilkunde > h1,
  .edelsteingalerie > h1,
  .karamkriya > h1,
  .seminare > h1 {
    margin-top: 0.8em;
  }

  div > .impressum,
  div > .kontakt,
  div > .datenschutz {
    margin-top: 0;
  }
  div.push {
    display: none;
  }
  .custom .kontakt {
    width: 100%;
    min-width: 325px;
    margin: 1.5em 0 4em 0;
  }
  .form-control {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .form-group:nth-of-type(3) {
    margin-bottom: 0;
  }

  .col-md-8.text-right {
    margin-bottom: 4em;

    text-align: center;
  }
  .form-group .control-label {
    width: 100%;

  }
  .col-md-8.text-right {
    padding: 0;

    text-align: center;
  }
  .yoga-gallery {
    text-align: center;

    img {
      float: none;
    }

    img:first-child {
      display: none;
    }

    img:nth-child(n) {
      margin: 5px 0;
    }
  }
  .subDatePos {
    margin: 0.5em 3.6rem;
  }
}

@media (max-width: 400px) {
  .subDatePos {
    margin: 0.5em 2em;
  }
}

