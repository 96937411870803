.intro-slider {
  display: flex;
  #slider {
    box-sizing: border-box;

    border-right: #ffffff 20px solid;
    border-left: #ffffff 20px solid;
  }
  & > :not(.swiper-container) {
    position: relative;

    float: left;

    box-sizing: content-box;
    width: 35%;
    padding: 1em;

    background-color: $turquoise;
  }

  .swiper-container {
    text-align: center;
  }

  .watermark {
    background-image: url(/images/3HO.png);
    background-repeat: no-repeat;
    background-position: bottom left;

    a {
      font-size: 1.3em;
      font-weight: 400;

      position: absolute;
      right: calc(50% - 120px);
      bottom: 2.5em;

      padding: 15px 20px;

      text-decoration: none;

      border: 4px solid white;
      &:hover {
        border: 4px solid #c2e1e6;
      }
    }

  }

  .caption {
    .inner {
      position: absolute;
      top: 20%;
      left: 25%;

      p {
        -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
        -o-animation: fadein 2s; /* Opera < 12.1 */
        animation: fadein 2s;
        -webkit-animation-delay: 1.5s; /* Safari 4.0 - 8.0 */
        animation-delay: 1.5s;

        opacity: 0;
        color: white;

        animation-fill-mode: forwards;

        &:first-of-type {
          font-size: 1.6em;
          font-weight: 400;
        }
        br {
          display: none;
        }
      }
    }
  }
}

@keyframes fadein {
  from {
    transform: translateY(-40px);

    opacity: 0;
  }
  to {
    transform: translateY(0);

    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    transform: translateY(-40px);

    opacity: 0;
  }
  to {
    transform: translateY(0);

    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    transform: translateY(-40px);

    opacity: 0;
  }
  to {
    transform: translateY(0);

    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    transform: translateY(-40px);

    opacity: 0;
  }
  to {
    transform: translateY(0);

    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    transform: translateY(-40px);

    opacity: 0;
  }
  to {
    transform: translateY(0);

    opacity: 1;
  }
}

.intro-slider {
  .caption {
    max-width: 80%;
  }
}

.intro-slider p {
  margin-right: 12%;
  margin-left: 15%;
}

@media (min-width: 1200px) and (max-width: 1720px) {
  .intro-slider {
    .caption {
      .inner {
        position: absolute;
        top: 10%;
        left: 10%;

        p {
          br {
            display: none;
          }
        }
      }
    }
  }
}

@media(max-width: $screen-md-max) {
  .intro-slider {
    .caption {
      .inner {
        position: absolute;
        top: 10%;
        left: 10%;

        p {
          br {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: 767px) and(max-width: 1181px) {
  .inner p:nth-of-type(2) {
    display: none;
  }
}

@media /*(min-width:767px) and*/
(max-width: $screen-xs-max) {
  .intro-slider .caption .inner p:first-of-type {
    font-size: 1.7em;
    padding-top: 4.2em;
  }
}

//@media (max-width: 500px) {
//  .intro-slider .caption .inner p:first-of-type {
//    font-size: 2em;
//    padding-top: 1em;
//  }
//}

@media (min-width: 768px) and (max-width: 900px) {
  .intro-slider .caption .inner p:nth-of-type(1) {
    font-size: 1.2em;
  }
}

@media (max-width: $screen-sm-max) {
  .intro-slider .watermark {
    background-size: 75%;
  }
}

@media(max-width: $screen-xs-max) {
  .intro-slider {
    position: relative;

    min-height: 50vh;

    #slider {
      border: 0;
      padding: 0;
    }

    .watermark {
      display: none;

      position: static;

      width: 100%;

      background-size: 50%;

      a {
        right: calc(50px);
      }
    }

    .caption {
      //display: none;
      z-index: 2;

      position: absolute;
      right: 0;

      width: 100%;
      max-width: 100%;

      background: transparent;

    }
    p.semi {
      font-size: 1.7em;
      padding-top: 1em;
    }
    p {
      font-size: 1.1em;
      margin-right: 11%;
      margin-left: 11%;
      text-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
      br {
        //display: none;
      }

      .inner {
        position: static;

        float: right;

        margin-right: auto;
        margin-left: auto;

        text-align: left;
      }
    }
  }
}

@media(max-width: $screen-xs-max) and (orientation: landscape) {
  .intro-slider {
    .watermark {
      background-size: 25%;
      a {
        padding: 10px 15px;

        bottom: 1em;
      }
    }
    .caption .inner p:first-of-type {
      padding-top: 0;
    }
  }
}

@media(min-width: 664px) and (max-width: $screen-xs-max) {
  .intro-slider .caption .inner p {
    margin-right: 15%;
    margin-left: 15%;
  }
}